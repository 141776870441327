<template>
  <v-app class>
    <v-alert
      v-model="alert"
      border="left"
      dark
      dismissible
      class="alert"
      :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}">
        {{alertMessage}}
    </v-alert>
    <div class="row">
      <div class="col-md-12 ml-auto mr-auto">
        <v-container>
          <div class="mb-4 d-flex ">
            <div class="col-md-11">
              <h2>Approve Medical Screenings</h2>
            </div>
          </div>
          <div v-if="isLoading" class="text-center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
          <v-data-table
            :headers= 'headers'
            :items="screenings"
            >
            <template v-slot:item.checkupDate="{ item }" >
                {{item.checkupDate | formatDate}}
            </template>
            <template v-slot:item.actions="{ item }" >
              <div class="d-flex">
                <Button :btnType="'Submit'" :label="'Approve'" @onClick="openConfirm(item)" class="mr-2"/>
                <Button :btnType="'Cancel'" :label="'Reject'" @onClick="openRejectModal(item)" class="mr-2"/>
                <Button :btnType="'Submit'" :label="'Forward'" @onClick="openPersonnelSearch(item)" class="mr-2"/>
              </div>
            </template>
          </v-data-table>
          <!-- Reject dialog  -->
          <v-dialog v-model="rejectDialog" persistent max-width="400">
            <v-card>
              <v-card-title class="headline text-center"><h3>Reason for rejecting</h3></v-card-title>
              <v-divider class="mx-4 mb-5"></v-divider>
              <v-card-text class="mt-5">
                <v-form ref="commentForm" v-model="valid">
                  <TextField  v-model="comments" :label="'Comment'"/>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <Button
                  :label="'Reject'"
                  :btnType="'Submit'"
                  @onClick="reject"
                  :isLoading="rejecting"
                  :disabled="!valid"
                  class="mr-4"
                />
                <v-btn color="green darken-1" text @click="rejectDialog = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
      </div>
    </div>
    <PersonnelSearch :dialog="personnelSearch" @selectPer="forward ($event)"  @close="personnelSearch = false" :type="'employee'" :title="'Personnel'">
      <Button
        :label="'Forward'"
        :btnType="'Submit'"
        @onClick="forwardRequest"
        :disabled="DelegateEmail === null"
        :isLoading="forwarding"
        class="mr-4"
      />
  </PersonnelSearch>
  <ConfirmationDialog :title="'Are you sure you want to submit?'" :isLoading="isProcessing" :dialog="dialog" :btnTitle="'Yes'" @close="dialog = false" @btnAction="approve"/>
  </v-app>
</template>
<script>

import Button from '@/components/ui/form/Button.vue'
import TextField from '@/components/ui/form/TextField'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import PersonnelSearch from '@/components/ui/PersonnelSearchModal.vue'
import { medicalScreeningService } from '@/services'

export default {
  components: {
    Button,
    TextField,
    PersonnelSearch,
    ConfirmationDialog
  },
  data () {
    return {
      valid: false,
      comments: '',
      headers: [
        {
          text: 'Personnel Name',
          value: 'employeeName'
        },
        {
          text: 'Job Title',
          value: 'jobTitle'
        },
        {
          text: 'Screening Name',
          value: 'screeningName'
        },
        {
          text: 'Checkup Date',
          value: 'checkupDate'
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ],
      personnelSearch: false,
      forwarding: false,
      DelegateEmail: '',
      DelegateName: '',
      DelegateCAI: '',
      dialog: false,
      searchTimesheet: '',
      isLoading: false,
      isProcessing: false,
      alertMessage: '',
      alert: false,
      alertType: '',
      screenings: [],
      rejectDialog: false,
      item: null,
      rejecting: false
    }
  },
  methods: {
    forwardRequest () {
      this.forwarding = true
      const payload = {
        ItemId: this.item.id,
        DelegateCAI: this.DelegateCAI,
        DelegateName: this.DelegateName,
        DelegateEmail: this.DelegateEmail
      }
      medicalScreeningService.forwardMedicalScreening(payload).then(() => {
        this.item = null
        this.showAlertMessage('Request successfully forwarded', 'success')
        this.getPending()
      }).catch(() => {
        this.showAlertMessage('Unable to approve screening, please try again later', 'error')
      }).finally(() => {
        this.forwarding = false
        this.personnelSearch = false
      })
    },
    openPersonnelSearch (item) {
      this.personnelSearch = true
      this.item = item
    },
    openConfirm (item) {
      this.item = item
      this.dialog = true
    },
    openRejectModal (item) {
      this.item = item
      this.rejectDialog = true
    },
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
    },
    forward (item) {
      this.DelegateEmail = item.emailAddress
      this.DelegateName = item.name
      this.DelegateCAI = item.id
    },
    getPending () {
      this.isLoading = true
      const pendingScreens = []
      this.screenings = []
      medicalScreeningService.pendingMedicalScreening().then((result) => {
        const data = result.data
        if (data.length > 0) {
          data.forEach(item => {
            const { firstName, lastName, middleName, jobTitle } = item.employee
            const pendingItem = {
              id: item.id,
              employeeName: `${lastName}, ${firstName} ${middleName}`,
              jobTitle: jobTitle.name,
              screeningName: item.medicalScreeningCategory.name,
              checkupDate: item.checkupDate
            }

            pendingScreens.push(pendingItem)
          })
          this.screenings = pendingScreens
        }
      }).catch(() => {}).finally(() => {
        this.isLoading = false
      })
    },
    approve () {
      this.isProcessing = true
      const approvals = []
      approvals.push({
        id: this.item.id,
        status: 1,
        comments: ''
      })

      medicalScreeningService.approveMedicalScreenings(approvals).then(() => {
        this.showAlertMessage('Screening successfully approved', 'success')
        this.getPending()
      }).catch(() => {
        this.showAlertMessage('Unable to approve screening', 'error')
      }).finally(() => {
        this.isProcessing = false
        this.dialog = false
      })
    },
    reject () {
      this.rejecting = true
      const approvals = []
      approvals.push({
        id: this.item.id,
        status: 2,
        comments: this.comments
      })

      medicalScreeningService.approveMedicalScreenings(approvals).then(result => {
        this.showAlertMessage('Screening successfully rejected', 'success')
        this.item = null
        this.rejectDialog = false
        this.getPending()
      }).catch(() => {
        this.showAlertMessage('Unable to reject screening', 'error')
      }).finally(() => {
        this.rejecting = false
      })
    }
  },
  mounted () {
    this.getPending()
  }
}
</script>
<style scoped>
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.iconAvatar {
  width: 122px;
}
.text-caption {
  font-weight: 600;
  font-size: 14px !important
}
</style>
